import React from "react";
import * as style from "./form.module.css";

const CheckboxText = ({ id, label, register }) => {
    return (
        <label htmlFor={id} className={style.checkbox}>
            <span>{label}</span>
            <input type="text" id={id} {...register(id)} />
        </label>
    );
};

export default CheckboxText;
