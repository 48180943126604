import React from "react";
import * as style from "./form.module.css";

const Checkbox = ({ id, value, label, register }) => {
    return (
        <label htmlFor={id} className={style.checkbox}>
            <input type="checkbox" id={id} value={value} {...register(id)} />
            {label}
        </label>
    );
};

export default Checkbox;
