import React from "react";
import * as style from "./form.module.css";

const Text = ({ id, label, required, maxLength, register, errors }) => {
        return (
            <label htmlFor={id} className={style.text}>
                <input
                    type="text"
                    placeholder={required ? "Required" : ""}
                    {...register(id, { required: required, maxLength: maxLength })}
                />
                {label}
                {errors?.[id]?.type === "required" && <span className={style.errorPopup}>This field is required.</span>}
                {errors?.[id]?.type === "maxLength" && <span className={style.errorPopup}>{`This field can be a maximum of ${maxLength} characters.`}</span>}
            </label>
        );
};

export default Text;
