import React, { useState } from "react";
import * as style from "./form.module.css";
import { useForm } from "react-hook-form";
import Text from "./Text";
import Checkbox from "./Checkbox";
import CheckboxText from "./CheckboxText";
import Radio from "./Radio";
import axios from "axios";
import { nanoid } from "nanoid";

const defaultValues = {
    Pronouns: "Undisclosed Pronouns",
    Disability: "No",
    Sharing: "Yes",
    Temporary: "Yes",
    Referrer: "Prefer not to say",
};

function encode(data) {
    const encoded = Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    return encoded;
}

Object.filterByKey = function (obj, predicate) {
    return Object.keys(obj)
        .filter((key) => predicate(key))
        .reduce((out, key) => {
            out[key] = obj[key];
            return out;
        }, {});
};

const Form = () => {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({ defaultValues });
    const [status, setStatus] = useState("Submit");
    const [error, setError] = useState(false);
    const [formId] = useState(nanoid());
    const watchDisability = watch(["Disability"]);

    const onSubmit = async (data, e) => {
        e.preventDefault();

        setStatus("Submitting")

        const rawData = Object.fromEntries(
            Object.entries(data).filter(
                ([key, value]) =>
                    !key.includes("Practice") && !key.includes("Pricerange") && !key.includes("Requirement") && !key.includes("Referrer")
            )
        );

        const practice = Object.fromEntries(Object.entries(data).filter(([key, value]) => key.includes("Practice")));
        const practiceFiltered = Object.entries(practice).filter(([key, value]) => value.length !== 0 && value !== false);
        const practiceCombined = [];
        practiceFiltered.forEach((child) => {
            if (!child[0].includes("Other")) {
                const childTrimmed = child[0].replace("Practice__", "");
                practiceCombined.push(childTrimmed);
            }
            if (child[0].includes("Other")) {
                practiceCombined.push(child[1]);
            }
        });

        const pricerange = Object.fromEntries(Object.entries(data).filter(([key, value]) => key.includes("Pricerange")));
        const pricerangeFiltered = Object.entries(pricerange).filter(([key, value]) => value.length !== 0 && value !== false);
        const pricerangeCombined = [];
        pricerangeFiltered.forEach((child) => {
            if (!child[0].includes("Other")) {
                const childTrimmed = child[0].replace("Pricerange__", "").replace("_", "–");
                pricerangeCombined.push("£".concat(childTrimmed));
            }
        });

        const requirement = Object.fromEntries(Object.entries(data).filter(([key, value]) => key.includes("Requirement")));
        const requirementFiltered = Object.entries(requirement).filter(([key, value]) => value.length !== 0 && value !== false);
        const requirementCombined = [];
        requirementFiltered.forEach((child) => {
            if (!child[0].includes("Other")) {
                const childTrimmed = child[0].replace("Requirement__", "");
                requirementCombined.push(childTrimmed);
            }
            if (child[0].includes("Other")) {
                requirementCombined.push(child[1]);
            }
        });

        const referrer = () => {
            if (data.Referrer__Other.length > 0) {
                return data.Referrer__Other;
            } else {
                return data.Referrer;
            }
        };

        const finalData = {
            ...rawData,
            Id: formId,
            Date: new Date(),
            Practice: practiceCombined.join(", "),
            Pricerange: pricerangeCombined.join(", "),
            Requirement: requirementCombined.join(", "),
            Referrer: referrer(),
        };

        try {
            await axios.post("/api/submit", {
                finalData,
            });
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({
                    "form-name": "Cameo Studios Form",
                    "Link to Submission": `https://studio.cameostudios.org/desk/forms;${formId}`,
                }),
            });
            setStatus("Thanks");
            reset();
        } catch (error) {
            setError(true);
            console.error(error);
        }
    };

    return (
        <>
            {status === "Submit" || status === "Submitting" ? (
                <>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <fieldset>
                            <legend className={style.legend}>About you:</legend>
                            <div className={style.pair}>
                                <Text id={"Forename"} label={"Forename"} required maxLength={50} errors={errors} register={register} />
                            </div>
                            <div className={style.pair}>
                                <Text id={"Surname"} label={"Surname"} required maxLength={50} errors={errors} register={register} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className={style.pair}>
                                <Text id={"Email"} label={"Email"} required maxLength={100} errors={errors} register={register} />
                            </div>
                            <div className={style.pair}>
                                <Text id={"Telephone"} label={"Telephone"} required={false} maxLength={50} register={register} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className={style.pair}>
                                <select {...register("Pronouns")}>
                                    <option value="Undisclosed Pronouns">Prefer not to say</option>
                                    <option value="He/Him">He/Him</option>
                                    <option value="She/Her">She/Her</option>
                                    <option value="They/Them">They/Them</option>
                                    <option value="Other">Other</option>
                                </select>
                                <label htmlFor="pronouns">Pronouns</label>
                            </div>
                            <div className={style.pair}>
                                <Text id={"Socials"} label={"Website/Social Media"} required={false} maxLength={50} register={register} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend className={style.legend}>Which category most closely describes your practice?</legend>
                            <div className={style.pairCheck}>
                                <Checkbox id={"Practice__Painting"} label={"Painting"} register={register} />
                                <Checkbox id={"Practice__Printmaking"} label={"Printmaking"} register={register} />
                                <Checkbox id={"Practice__Film/Video"} label={"Film/Video"} register={register} />
                                <Checkbox id={"Practice__Digital"} label={"Digital/Multimedia"} register={register} />
                            </div>
                            <div className={style.pairCheck}>
                                <Checkbox id={"Practice__Sculpture/Installation"} label={"Sculpture/Installation"} register={register} />
                                <Checkbox id={"Practice__Photography"} label={"Photography"} register={register} />
                                <Checkbox id={"Practice__Live"} label={"Live Art"} register={register} />
                                <CheckboxText id={"Practice__Other"} label={"Other"} register={register} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend className={style.legend}>Are any of the following an essential requirement of a studio?</legend>
                            <div className={style.pairCheck}>
                                <Checkbox id={"Requirement__Groundfloor"} label={"Ground Floor"} register={register} />
                                <Checkbox id={"Requirement__Ventilation"} label={"Ventilation"} register={register} />
                                <Checkbox id={"Requirement__Lift_Access"} label={"Lift Access"} register={register} />
                                <Checkbox id={"Requirement__Heating"} label={"Heating"} register={register} />
                            </div>
                            <div className={style.pairCheck}>
                                <Checkbox id={"Requirement__Light"} label={"Natural Light"} register={register} />
                                <Checkbox id={"Requirement__Transport"} label={"Close to public transport"} register={register} />
                                <Checkbox id={"Requirement__Self_Contained"} label={"Self-contained"} register={register} />
                                <CheckboxText id={"Requirement__Other"} label={"Other"} register={register} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend className={style.legend}>
                                Do you consider yourself to have a disability, impairment, or long term condition that might impact on your
                                choice of studio?
                            </legend>
                            <Radio id={"Disability__Yes"} groupName={"Disability"} label={"Yes"} register={register} />
                            <Radio id={"Disability__No"} groupName={"Disability"} label={"No"} register={register} />
                        </fieldset>
                        <fieldset style={{ display: watchDisability[0] === "Yes" ? "block" : "none" }}>
                            <legend className={style.legend}>Please give further details:</legend>
                            <textarea
                                id="Disability__Details"
                                rows="5"
                                defaultValue={watchDisability[0] === "No" ? "N.A." : ""}
                                {...register("Disability__Details")}
                            />
                        </fieldset>
                        <fieldset>
                            <legend className={style.legend}>
                                Please indicate the price range of the studio you are seeking (per calendar month):
                            </legend>
                            <div className={style.pairCheck}>
                                <Checkbox id={"Pricerange__100_150"} label={"£100 – £150"} register={register} />
                                <Checkbox id={"Pricerange__200_250"} label={"£200 – £250"} register={register} />
                                <Checkbox id={"Pricerange__300_350"} label={"£300 – £350"} register={register} />
                                <Checkbox id={"Pricerange__400_500"} label={"£400 – £500"} register={register} />
                            </div>
                            <div className={style.pairCheck}>
                                <Checkbox id={"Pricerange__150_200"} label={"£150 – £200"} register={register} />
                                <Checkbox id={"Pricerange__250_300"} label={"£250 – £300"} register={register} />
                                <Checkbox id={"Pricerange__350_400"} label={"£350 – £400"} register={register} />
                                <Checkbox id={"Pricerange__500+"} label={"£500+"} register={register} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend className={style.legend}>Would you share a studio?</legend>
                            <Radio id={"Sharing__Yes"} groupName={"Sharing"} label={"Yes"} register={register} checked />
                            <Radio id={"Sharing__No"} groupName={"Sharing"} label={"No"} register={register} />
                        </fieldset>
                        <fieldset>
                            <legend className={style.legend}>Would you take a temporary studio? (e.g. for 6 – 12 months)</legend>
                            <Radio id={"Temporary__Yes"} groupName={"Temporary"} label={"Yes"} register={register} />
                            <Radio id={"Temporary__No"} groupName={"Temporary"} label={"No"} register={register} />
                        </fieldset>
                        <fieldset>
                            <legend className={style.legend}>How did you find out about Cameo Studios?</legend>
                            <div className={style.pairCheck}>
                                <Radio
                                    id={"Referrer__Instagram"}
                                    groupName={"Referrer"}
                                    label={"Social Media (Instagram)"}
                                    register={register}
                                />
                                <Radio
                                    id={"Referrer__Facebook"}
                                    groupName={"Referrer"}
                                    label={"Social Media (Facebook)"}
                                    register={register}
                                />
                                <Radio
                                    id={"Referrer__Twitter"}
                                    groupName={"Referrer"}
                                    label={"Social Media (Twitter)"}
                                    register={register}
                                />
                                <Radio id={"Referrer__Newsletter"} groupName={"Referrer"} label={"Newsletter"} register={register} />
                            </div>
                            <div className={style.pairCheck}>
                                <Radio id={"Referrer__Word_of_Mouth"} groupName={"Referrer"} label={"Word of Mouth"} register={register} />
                                <Radio id={"Referrer__Search_Engine"} groupName={"Referrer"} label={"Search Engine"} register={register} />
                                <Radio id={"Referrer__Unknown"} groupName={"Referrer"} label={"Prefer not to say"} register={register} />
                                <CheckboxText id={"Referrer__Other"} groupName={"Referrer"} label={"Other"} register={register} />
                            </div>
                        </fieldset>
                        {error && (
                            <p className={style.error}>
                                We’re sorry, there’s been an error submitting your form. Please try again our contact us directly via our
                                email address.
                            </p>
                        )}
                        <input type="submit" className={style.submit} value={status} />
                    </form>
                    <form name="Cameo Studios Form" data-netlify="true" method="post" style={{ display: "none" }}>
                        <div className={style.pair}>
                            <Text id={"Link to Submission"} label={"Link to Submission"} register={register} />
                        </div>
                    </form>
                </>
            ) : (
                <div className={style.thanks}>
                    Your form has successfully been submitted. Thank you!
                    <br />
                    <br />
                    We’ll be in touch soon.
                </div>
            )}
        </>
    );
};

export default Form;
