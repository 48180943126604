// extracted by mini-css-extract-plugin
export var checkbox = "form-module--checkbox--vbmJA";
export var error = "form-module--error--VYmDM";
export var errorPopup = "form-module--errorPopup--sqeWS";
export var form = "form-module--form--GSmYU";
export var hidden = "form-module--hidden--UiId-";
export var legend = "form-module--legend--5LA0k";
export var pair = "form-module--pair--bP4IB";
export var pairCheck = "form-module--pairCheck--5Emb7";
export var stack = "form-module--stack--NN27U";
export var submit = "form-module--submit--gG+HH";
export var text = "form-module--text--l3M00";
export var thanks = "form-module--thanks--D6VGC";