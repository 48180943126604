import React from "react";
import * as style from "../styles/contact.module.css";
import Toggle from "../components/toggle/Toggle";
import Form from "../components/form/Form";
import { graphql, Link } from "gatsby";
import { PortableText } from "@portabletext/react";


const components = {
    marks: {
        link: ({ value, children }) => {
            const type = () => {
                switch (value.type) {
                    case "email":
                        return (
                            <a
                                className={style.link}
                                href={`mailto:${value.link}?subject=${value.subject}`}
                            >
                                {children}
                            </a>
                        );
                    case "phone":
                        return (
                            <a className={style.link} href={`tel:${value.link}`}>
                                {children}
                            </a>
                        );
                    default:
                        return (
                            <a
                                className={style.link}
                                href={`${value.link}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {children}
                            </a>
                        );
                }
            };
            return type();
        },
    },
};

const Contact = ({ data: {contact} }) => {
    return (
        <main className={style.contact}>
            <div className={style.toggleWrapper}>
                <Toggle to={""} colour={"#000000"} rotate />
            </div>
            <div className={style.hidden}>
                <Form />
            </div>
            <div className={style.text}>
                <PortableText value={contact._rawContact} components={components} />
                <div className={style.landlord}>
                    <PortableText value={contact._rawSubtitle} components={components} />
                    <Link to="/privacy" className={style.privacy}>
                        Privacy Policy
                    </Link>
                </div>
                <div className={style.socials}>
                    <PortableText value={contact._rawSocials} components={components} />
                </div>
            </div>
        </main>
    );
};

export default Contact;

export const query = graphql`
    
        query ContactQuery {
            contact:sanityContact {
                _rawContact
                _rawSocials
                _rawSubtitle
            }
        }
    
`;
