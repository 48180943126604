import React from "react";
import * as style from "./form.module.css";

const Radio = ({ id, groupName, label, register }) => {
    return (
        <label htmlFor={id} className={style.checkbox}>
            <input type="radio" id={id} name={groupName} value={label} {...register(groupName)} />
            {label}
        </label>
    );
};

export default Radio;
